import styled from "styled-components";

export const Container = styled.div`
    @media (max-width: 480px) {
        padding: 22px 10px;

    }

    padding: 30px;
    background-color: black;
    color: white;
    min-height: 100vh;

`;

export const Main = styled.main`
    display: flex;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`

export const TextContainer = styled.div`
    font-size: 15px;
    line-height: 1.1;
    max-width: 269px;
    margin: 0;

    p {
        &:first-child {
            margin-bottom: 16.5px;
        }

        

    }

    a {
        @media (max-width: 480px) {
            margin-top: 40px;
        }
        margin-top: 115px;
		color: white;
		display: block;
    }
`

export const ImageContainer = styled.div`
    @media (max-width: 1366px) {
        margin-left: 102px;
    }

    @media (max-width: 1133px) {
        margin-left: 13px;
    }

    @media (max-width: 768px) {
        margin-left: -24px;
        margin-top: 40px;
    }

    @media (max-width: 480px) {
        margin-top: 10px;
    }

    margin-top: 4px;
    position: relative;

    img {
        height: 834px;

        @media (max-width: 1133px) {
            height: 608px;
        }
    }


`

export const Pin = styled.span<{ $top: number, $left: number }>`

    position: absolute;
    top: ${({$top}) => $top}px;
    left: ${({$left}) => $left}px;
    width: max-content;
    min-width: 239px;
    padding-left: 125px;
    font-size: 15px;
    line-height: 1.1;

    @media (max-width: 1133px) {
        top: ${({$top}) => $top / 1.3717}px;
        left: ${({$left}) => $left / 1.3717}px;
    }

    @media (max-width: 480px) {
        min-width: 200px;
        padding-left: 65px;
    }

    &:before {
        content: "";
        display: block;
        background-color: white;
        height: 1px;
        width: 100%;
        position: absolute;
        top: 19px;
        left: 0;
    }

    ul {
        margin-top: 16px;
    }
`
